import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JiraApiService {

  constructor(private http: HttpClient) { }

  createTask(osType, osVersion, deviceName, description) {
    return this.http.post<any>('/issue',
    {
      'fields': {
        'project': {
          'id': '10003'
        },
        'summary': deviceName + ' | ' + osType + ' | ' + osVersion + ' : ' + description.substring(0, 75),
        'description': description,
        'issuetype': {
          'id': '10015'
        },
        'labels': [deviceName, osType, osVersion]
      }
    }
    );
  }
}
