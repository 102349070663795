import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JiraApiService } from '../jira-api.service';

@Component({
  selector: 'app-error-form',
  templateUrl: './error-form.component.html',
  styleUrls: ['./error-form.component.scss']
})
export class ErrorFormComponent implements OnInit {
  optionsSelectOs = [
    { value: 'ANDROID', label: 'Android' },
    { value: 'IOS', label: 'Apple IOS' }
  ];
  optionsSelectVersions = [];
  form: FormGroup;
  submitting = false;
  error = undefined;
  success = false;

  optionsSelectAndroid = [
    { value: '', label: 'Pie', group: true },
    { value: '9.0.0', label: '9.0' },
    { value: '', label: 'Oreo', group: true },
    { value: '8.1.0', label: '8.1' },
    { value: '8.0.0', label: '8.0' },
    { value: '', label: 'Nougat', group: true },
    { value: '7.1.2', label: '7.1.2' },
    { value: '7.1.1', label: '7.1.1' },
    { value: '7.1.0', label: '7.1' },
    { value: '7.0.0', label: '7.0' },
    { value: '', label: 'Marshmallow', group: true },
    { value: '6.0.1', label: '6.0.1' },
    { value: '6.0.0', label: '6.0' },
    { value: '', label: 'Lollipop', group: true },
    { value: '5.1.1', label: '5.1.1' },
    { value: '5.1.0', label: '5.1' },
    { value: '5.0.2', label: '5.0.2' },
    { value: '5.0.1', label: '5.0.1' },
    { value: '5.0.0', label: '5.0' },
    { value: '', label: 'Kitkat', group: true },
    { value: '4.4.4', label: '4.4.4' },
    { value: '4.4.3', label: '4.4.3' },
    { value: '4.4.2', label: '4.4.2' },
    { value: '4.4.1', label: '4.4.1' },
    { value: '4.4.0', label: '4.4' }
  ];

  optionsSelectIOS = [
    { value: '', label: 'IOS 12', group: true },
    { value: '12.3.2', label: '12.3.2' },
    { value: '12.3.1', label: '12.3.1' },
    { value: '12.3.0', label: '12.3' },
    { value: '12.2.0', label: '12.2' },
    { value: '12.1.4', label: '12.1.4' },
    { value: '12.1.3', label: '12.1.3' },
    { value: '12.1.2', label: '12.1.2' },
    { value: '12.1.1', label: '12.1.1' },
    { value: '12.1.0', label: '12.1' },
    { value: '12.0.1', label: '12.0.1' },
    { value: '12.0.0', label: '12.0' },
    { value: '', label: 'IOS 11', group: true },
    { value: '11.4.1', label: '11.4.1' },
    { value: '11.4.0', label: '11.4' },
    { value: '11.3.1', label: '11.3.1' },
    { value: '11.3.0', label: '11.3' },
    { value: '11.2.6', label: '11.2.6' },
    { value: '11.2.5', label: '11.2.5' },
    { value: '11.2.2', label: '11.2.2' },
    { value: '11.2.1', label: '11.2.1' },
    { value: '11.2.0', label: '11.2' },
    { value: '11.1.2', label: '11.1.2' },
    { value: '11.1.1', label: '11.1.1' },
    { value: '11.1.0', label: '11.1.0' },
    { value: '11.0.3', label: '11.0.3' },
    { value: '11.0.2', label: '11.0.2' },
    { value: '11.0.1', label: '11.0.1' },
    { value: '11.0.0', label: '11.0' },
    { value: '', label: 'IOS 10', group: true },
    { value: '10.3.3', label: '10.3.3' },
    { value: '10.3.2', label: '10.3.2' },
    { value: '10.3.1', label: '10.3.1' },
    { value: '10.3.0', label: '10.3' },
    { value: '10.2.1', label: '10.2.1' },
    { value: '10.2.0', label: '10.2' },
    { value: '10.1.1', label: '10.1.1' },
    { value: '10.1.0', label: '10.1' },
    { value: '10.0.3', label: '10.0.3' },
    { value: '10.0.2', label: '10.0.2' },
    { value: '10.0.1', label: '10.0.1' }
  ];

  constructor(private fb: FormBuilder, private service: JiraApiService) {}

  ngOnInit() {
    this.form = this.fb.group({
      osType: ['', [Validators.required]],
      osVersion: ['', [Validators.required]],
      device: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  osSelected(os) {
    if (os.value === 'IOS') {
      this.optionsSelectVersions = this.optionsSelectIOS;
    } else {
      this.optionsSelectVersions = this.optionsSelectAndroid;
    }
  }

  onSubmit() {
    this.submitting = true;
    this.service
      .createTask(
        this.form.value.osType,
        this.form.value.osVersion,
        this.form.value.device.replace(' ', ''),
        this.form.value.description
      )
      .subscribe(result => {
        this.submitting = false;
        console.log(result);
        if (result.body && result.body.errors) {
          this.error = result.body.errors.labels;
          this.success = false;
        } else {
          this.success = true;
          this.error = undefined;
        }
      }, error => {
        this.success = false;
        this.submitting = false;
      });
    console.log(this.form.value);
  }
}
